import { FileImage, FileVideo } from 'lucide-react'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { getFileMediaType } from '@/utils/fileExtensions'
import { type Page } from '@/api/pages/types'
import PlaceholderV2 from '@/assets/placeholder-img.png'
import { Checkbox } from '../shadcn/Checkbox'

type Props = {
  page: Page
  checkedPages: string[]
  handlePageCheck: (pageId: string) => void
}

const ListItem = ({ page, checkedPages, handlePageCheck }: Props) => {
  const mediaType = getFileMediaType(page.imageS3Key)

  const mediaSource = mediaType === 'video' ? page.coverS3Key : page.imageS3Key

  const imageUrl = mediaSource ? getStoryAssetImageUrl(mediaSource) : PlaceholderV2

  return (
    <button
      className="flex items-center space-x-6 border-b px-4 pb-4 pt-8 last:border-b-0  hover:bg-gray-100"
      key={page.id}
      onClick={() => handlePageCheck(page.id)}
    >
      <Checkbox className="size-4" checked={checkedPages.includes(page.id)} onCheckedChange={() => handlePageCheck(page.id)} />
      <div className="relative shrink-0">
        <img src={imageUrl} alt="Page cover" className="h-40 w-24 rounded object-cover" />
        <div className="absolute left-1 top-1 z-10">
          <div className="flex size-4 items-center justify-center rounded bg-white/70 text-slate-700">
            {getFileMediaType(page.imageS3Key) === 'video' && <FileVideo size={12} />}
            {getFileMediaType(page.imageS3Key) === 'image' && <FileImage size={12} />}
          </div>
        </div>
      </div>
      <p className="text-sm font-semibold">{page.label}</p>
    </button>
  )
}

export default ListItem

import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useDeleteStory from '@/api/stories/mutations/useDeleteStory'
import { OrderProvider } from '@/providers/OrderProvider'
import { Story } from '@/api/stories/types'
import ActionsDetailStoryCard from './ActionsDetailStoryCard'

import StoryPages from '../StoryPages'

type NewStoryCardProps = {
  story: Story
}

const DetailStoryCard = ({ story }: NewStoryCardProps) => {
  const navigate = useNavigate()
  const { isSuccess } = useDeleteStory()

  useEffect(() => {
    if (isSuccess) {
      navigate('/')
    }
  }, [isSuccess, navigate])

  return (
    <div className="w-full rounded-2xl bg-gray-50 p-6">
      <div className="divide-slate/200 mb-5 flex flex-col divide-y">
        <OrderProvider>
          <ActionsDetailStoryCard story={story} />
          <StoryPages story={story} />
        </OrderProvider>
      </div>
    </div>
  )
}

export default DetailStoryCard

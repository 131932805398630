import { useMemo, useState } from 'react'
import { FieldValues, useFormContext, UseFormGetValues, UseFormSetError, UseFormSetValue } from 'react-hook-form'
import { Aperture, Crop } from 'lucide-react'
import { toast } from 'sonner'
import { Page } from '@/api/pages/types'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { Button } from '@/components/shadcn/Button'
import { getFileMediaType } from '@/utils/fileExtensions'
import Loader from '@/components/Loader'
import { getImage } from '@/api/stories/requests'
import PreviewMedia from './PreviewMedia'

interface PreviewControllerProps {
  isNew: boolean
  mediaFile: File | null | undefined
  onMediaChange: (
    data: File | null,
    getValues: UseFormGetValues<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    setError: UseFormSetError<FieldValues>
  ) => void
  onCoverChange: (
    data: File | Blob,
    getValues: UseFormGetValues<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    setError: UseFormSetError<FieldValues>
  ) => void
}

let cachedObjectUrl: string | null = null
let cachedMediaFile: File | null = null

const pickMediaToShow = (mediaFile: File | null | undefined, isNew: boolean, s3image: string, coverS3Key: string | null) => {
  if (isNew && !s3image) return null

  if (mediaFile) {
    if (mediaFile !== cachedMediaFile) {
      if (cachedObjectUrl) {
        URL.revokeObjectURL(cachedObjectUrl)
      }
      cachedObjectUrl = URL.createObjectURL(mediaFile)
      cachedMediaFile = mediaFile
    }
    return cachedObjectUrl
  }

  cachedMediaFile = null
  if (cachedObjectUrl) {
    URL.revokeObjectURL(cachedObjectUrl)
    cachedObjectUrl = null
  }

  if (coverS3Key) return getStoryAssetImageUrl(coverS3Key)

  if (s3image) return getStoryAssetImageUrl(s3image)

  return null
}

const PreviewController = ({ isNew, mediaFile, onMediaChange, onCoverChange }: PreviewControllerProps) => {
  const { getValues, setValue, setError, getFieldState, watch } = useFormContext()

  const [isCropLoading, setIsCropLoading] = useState(false)

  const imageS3Key = watch('imageS3Key')

  const originalS3Key = watch('originalS3Key')

  const page = getValues() as Page
  const mediaType = getFileMediaType(mediaFile?.name ?? '')
  const coverS3KeyError = getFieldState('coverS3Key')?.error
  const cropBBox = getValues('cropBBox')

  const mediaToShow = useMemo(
    () => pickMediaToShow(mediaFile, isNew, page.imageS3Key, page.coverS3Key),
    [mediaFile, page.imageS3Key, isNew, page.coverS3Key]
  )

  const captureImageFromVideo = async () => {
    const video = document.getElementById('story-video') as HTMLVideoElement
    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d')?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
    const blob = await new Promise<Blob | null>(resolve => {
      canvas.toBlob(resolve)
    })
    if (!blob) {
      return
    }
    onCoverChange(blob, getValues, setValue, setError)
  }

  const handleCrop = async () => {
    setIsCropLoading(true)
    const originalS3Key = getValues('originalS3Key')
    let file: File | null = null

    try {
      if (originalS3Key) {
        const blob = await getImage(originalS3Key)

        file = new File([blob], originalS3Key, { type: blob.type })
      }

      if (mediaFile) file = mediaFile

      onMediaChange(file, getValues, setValue, setError)
      setIsCropLoading(false)
    } catch (error) {
      setIsCropLoading(false)
      toast.error('Nepodařilo se načíst obrázek pro oříznutí')
    }
  }

  const displayCropButton = (mediaType === 'image' && isNew && imageS3Key) || (mediaType !== 'video' && originalS3Key)

  return (
    <div className="flex flex-col gap-4">
      <PreviewMedia mediaType={mediaType ?? 'image'} mediaUrl={mediaToShow} cropBox={cropBBox} />
      {displayCropButton && (
        <Button type="button" onClick={handleCrop} variant="outline" disabled={isCropLoading}>
          {isCropLoading ? <Loader className="mr-2 size-4" /> : <Crop className="mr-2 size-4" />}
          Oříznout fotku
        </Button>
      )}
      {mediaType === 'video' && (
        <Button
          variant="outline"
          className="flex w-40 gap-1"
          type="button"
          onClick={() => {
            captureImageFromVideo()
          }}
        >
          <Aperture size={20} />
          Zachytit náhled
        </Button>
      )}
      {coverS3KeyError && <p className="w-40 text-sm text-red-500">{coverS3KeyError.message?.toString()}</p>}
    </div>
  )
}

export default PreviewController

import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { continueGeneratingPages } from '@/api/pages/requests'
import { AddStoryPageResponse } from '@/api/pages/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'

const useContinueGeneratingPages = (
  config?: UseMutationOptions<AddStoryPageResponse, ResponseError, string>
): UseMutationResult<AddStoryPageResponse, ResponseError, string> =>
  useMutation({
    mutationKey: [Keys.CONTINUE_GENERATING_PAGES],
    mutationFn: storyId => continueGeneratingPages(storyId),
    ...config,
  })

export default useContinueGeneratingPages

import { Link } from 'lucide-react'
import { useState } from 'react'
import CopyExistingStoryForm from './StoryUrlForm'
import PageList from './PageList'
import { Button } from '../shadcn/Button'
import { Dialog, DialogContent, DialogTrigger } from '../shadcn/Dialog'
import { MultiStepper } from '../Stepper/MultiStepper'
import { MultiStepperProvider } from '../Stepper/useMultiStepper'

const CopyExistingStoryDialog = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dialog open={isOpen} onOpenChange={isOpen => setIsOpen(isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" className="flex gap-2" onClick={() => setIsOpen(!isOpen)}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link size={20} />
          Vložit existující
        </Button>
      </DialogTrigger>
      <DialogContent>
        <MultiStepperProvider totalSteps={2} initialStep={0} initState={undefined}>
          <MultiStepper
            steps={[<CopyExistingStoryForm key="story-form" setIsOpen={setIsOpen} />, <PageList key="page-list" setIsOpen={setIsOpen} />]}
          />
        </MultiStepperProvider>
      </DialogContent>
    </Dialog>
  )
}

export default CopyExistingStoryDialog

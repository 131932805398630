import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CopyExistingPagesBody } from '@/api/stories/types'
import Keys from '@/api/keys'
import { copyExistingPages } from '../requests'

const useCopyExistingPages = ({ storyId }: { storyId: string }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [Keys.COPY_EXISTING_STORY],
    mutationFn: (payload: CopyExistingPagesBody) => copyExistingPages({ ...payload, storyId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [Keys.STORY_PAGES] })
    },
  })
}

export default useCopyExistingPages

import React, { useState } from 'react'
import EmojiPicker from 'emoji-picker-react'
import { Plus } from 'lucide-react'
import { Controller, useFormContext } from 'react-hook-form'
import { type EmojiClickData } from 'emoji-picker-react'
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Button } from '@/components/shadcn/Button'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  defaultOpen?: boolean
  label?: string
}

const EmojiInput = ({ label, name, defaultOpen }: InputProps) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(defaultOpen)
  const { control } = useFormContext()

  const handleEmojiSelect = (emojiEvent: EmojiClickData) => {
    setShowEmojiPicker(false)

    return { target: { value: emojiEvent.emoji } }
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field: { value, onChange } }) => (
        <div className="flex flex-col space-y-2">
          <p className="mb-1 text-sm font-bold">{label}</p>
          <div className="relative flex items-center">
            <Popover open={showEmojiPicker} onOpenChange={setShowEmojiPicker} modal>
              <PopoverTrigger>
                <Button type="button" variant="outline" className="flex size-12 gap-2 rounded-full text-2xl">
                  {value || <Plus />}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="z-50" side="right" sideOffset={10}>
                <EmojiPicker
                  open
                  lazyLoadEmojis
                  allowExpandReactions
                  reactionsDefaultOpen
                  skinTonesDisabled
                  onEmojiClick={(emojiEvent: EmojiClickData) => onChange(handleEmojiSelect(emojiEvent))}
                />
              </PopoverContent>
            </Popover>
          </div>
          <div />
        </div>
      )}
    />
  )
}

export default EmojiInput

import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'
import { ResponseError } from '@/api/errors'
import { generateArticle } from '@/api/stories/requests'
import { GenerateArticleBody, GenerateArticleResponse } from '@/api/stories/types'
import Keys from '@/api/keys'

const useGenerateArticle = (
  config?: UseMutationOptions<GenerateArticleResponse, ResponseError, GenerateArticleBody>
): UseMutationResult<GenerateArticleResponse, ResponseError, GenerateArticleBody> => {
  return useMutation({
    mutationKey: [Keys.GENERATE_STORY],
    mutationFn: (payload: GenerateArticleBody) => generateArticle(payload),
    ...config,
  })
}

export default useGenerateArticle

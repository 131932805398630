import { useCallback, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import useStoryPages from '@/api/stories/queries/useStoryPages'
import useOrderPages from '@/api/stories/mutations/useOrderPages'
import { Page } from '@/api/pages/types'
import { useModal } from '@/stores/useModal'
import { Story } from '@/api/stories/types'
import StoryPageSortable from '../StoryPageSortable'
import AddCard from '../AddCard/Index'
import PageCard from '../StoryPage'
import OrderedContent from '../OrderedContent'
import ContinueGenerationCard from '../ContinueGenerationCard'

type Props = { story: Story }

const StoryPages = ({ story }: Props) => {
  const { data } = useStoryPages({ storyId: story.id })

  const { onOpen } = useModal()

  const orderPagesMutation = useOrderPages({ storyId: story.id })

  const methods = useForm<{ pages: Page[] }>()

  const { control, reset, handleSubmit, setValue } = methods

  const { fields, move } = useFieldArray({ control, name: 'pages', keyName: 'fieldId' })

  const showContinueGeneratingCard = data?.some(page => page.generated) && story.lastGeneratedImageId

  useEffect(() => {
    if (data) {
      reset({ pages: data ?? [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const afterDragEnd = useCallback(
    (activeIndex: number, overIndex: number) => {
      move(activeIndex, overIndex)

      fields.forEach((_, index) => {
        setValue(`pages.${index}.order`, fields.length - index + 1)
      })

      handleSubmit(data => orderPagesMutation.mutate(data.pages))()
    },
    [move, fields, setValue, handleSubmit, orderPagesMutation]
  )

  return (
    <div className="items-top flex flex-wrap items-center justify-start gap-7 py-4">
      {showContinueGeneratingCard && <ContinueGenerationCard storyId={story.id} />}
      <OrderedContent
        afterDragEnd={afterDragEnd}
        sortableNodes={fields?.map(page => <StoryPageSortable key={page.id} page={page} />)}
        fields={fields}
        renderOverlay={page => <PageCard page={page} />}
      />
      <AddCard create={onOpen} />
    </div>
  )
}

export default StoryPages

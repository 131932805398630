import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import useStoryPagesByUrl from '@/api/stories/queries/useStoryPagesByUrl'
import useCopyExistingPages from '@/api/stories/mutations/useCopyExistingPages'
import ListItem from './ListItem'
import { useMultiStepper } from '../Stepper/useMultiStepper'
import { DialogFooter, DialogHeader, DialogTitle } from '../shadcn/Dialog'
import { Button } from '../shadcn/Button'
import DialogScrollArea from '../DialogScrollArea'
import { Skeleton } from '../shadcn/Skeleton'
import { Alert, AlertDescription, AlertTitle } from '../shadcn/Alert'

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const PageList = ({ setIsOpen }: Props) => {
  const { state } = useMultiStepper()

  const [checkedPages, setCheckedPages] = useState<string[]>([])

  const { storyId } = useParams<{ storyId: string }>()

  const handlePageCheck = (pageId: string) => {
    if (checkedPages.includes(pageId)) {
      setCheckedPages(checkedPages.filter(id => id !== pageId))
    } else {
      setCheckedPages([...checkedPages, pageId])
    }
  }

  const { mutate } = useCopyExistingPages({ storyId: storyId! })

  const { data, isPending, isError } = useStoryPagesByUrl({ storyUrl: state.storyUrl })

  const handleAdd = () => {
    mutate({ pageIds: checkedPages }, { onSuccess: () => setIsOpen(false) })
  }

  if (isPending) {
    return (
      <>
        <DialogHeader>
          <DialogTitle>Dotazování se na kolekci</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-4">
          <Skeleton className="h-32 w-32 rounded-full" />
          <div className="flex flex-col space-y-4">
            <Skeleton className="h-6 w-52" />
            <Skeleton className="h-6 w-52" />
          </div>
        </div>
      </>
    )
  }

  if (isError) {
    return (
      <>
        <DialogHeader>
          <DialogTitle>Došlo k chybě</DialogTitle>
        </DialogHeader>
        <Alert variant="destructive">
          <AlertTitle>Chyba!</AlertTitle>
          <AlertDescription>Při načítání existujícíh příběhů došlo k chybě. Opravdu příběh existuje?</AlertDescription>
        </Alert>
      </>
    )
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>Vyberte konkrétní příběh/y</DialogTitle>
      </DialogHeader>
      <DialogScrollArea>
        <div className="flex flex-col">
          {data.map(page => (
            <ListItem key={page.id} page={page} checkedPages={checkedPages} handlePageCheck={handlePageCheck} />
          ))}
        </div>
      </DialogScrollArea>
      <DialogFooter>
        <Button type="button" variant="ghost" onClick={() => setIsOpen(false)}>
          Zrušit
        </Button>
        <Button type="button" disabled={checkedPages.length === 0} onClick={handleAdd}>
          Přidat ({checkedPages.length})
        </Button>
      </DialogFooter>
    </>
  )
}

export default PageList

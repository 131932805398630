import { Button } from '@/components/shadcn/Button'
import { cn } from '@/utils/shadcn/cn'
import { useMultiStepper } from '@/components/Stepper/useMultiStepper'
import useStoryPageTemplates from '@/api/pages/queries/useStoryPageTemplates'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { DialogFooter, DialogHeader, DialogTitle } from '@/components/shadcn/Dialog'
import { Alert, AlertDescription, AlertTitle } from '@/components/shadcn/Alert'
import DialogScrollArea from '@/components/DialogScrollArea'
import TemplatesSkeleton from './TemplatesSkeleton'

const TemplatePicker = () => {
  const { nextStep, setState, state } = useMultiStepper()
  const { data, isPending, isError } = useStoryPageTemplates()

  const continueWithoutTemplate = () => {
    setState({ idTemplate: null })
    nextStep()
  }

  if (isError)
    return (
      <Alert variant="destructive">
        <AlertTitle>Chyba</AlertTitle>
        <AlertDescription>Při načítání šablon došlo k chybě.</AlertDescription>
      </Alert>
    )

  return (
    <>
      <DialogHeader>
        <DialogTitle>Výběr šablony</DialogTitle>
      </DialogHeader>
      <DialogScrollArea>
        <div className="grid h-full grid-cols-3 justify-items-center gap-8">
          {isPending ? (
            <TemplatesSkeleton />
          ) : (
            data.map(template => (
              <div
                key={template.name}
                aria-hidden="true"
                onClick={() => setState({ idTemplate: template.id })}
                className={cn(
                  'flex h-[464px] cursor-pointer flex-col items-start space-y-2 rounded-lg p-5 transition-all duration-300',
                  state.idTemplate === template.id && 'bg-zinc-100 shadow-sm'
                )}
              >
                <img src={getStoryAssetImageUrl(template.coverS3Key ?? '')} alt={template.name ?? ''} width={174} height={324} />
                <h3>{template.name}</h3>
                <p className="text-sm font-normal text-zinc-500">{template.description}</p>
              </div>
            ))
          )}
        </div>
      </DialogScrollArea>
      <DialogFooter className="flex w-full justify-end">
        <Button onClick={continueWithoutTemplate} variant="ghost">
          Pokračovat bez šablony
        </Button>
        <Button onClick={nextStep}>Pokračovat k editaci</Button>
      </DialogFooter>
    </>
  )
}

export default TemplatePicker

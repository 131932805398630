import { Skeleton } from '@/components/shadcn/Skeleton'

const TemplatesSkeleton = () => (
  <>
    <Skeleton className="h-[464px] w-full rounded-lg" />
    <Skeleton className="h-[464px] w-full rounded-lg" />
    <Skeleton className="h-[464px] w-full rounded-lg" />
  </>
)

export default TemplatesSkeleton

import { QueryObserverOptions, useQuery, UseQueryResult } from '@tanstack/react-query'
import Keys from '@/api/keys'
import { getStoryPagesByUrl } from '@/api/stories/requests'
import { GetStoryPagesByUrlParams, GetStoryPagesByUrlResponse } from '@/api/stories/types'

const useStoryPagesByUrl = (
  params: GetStoryPagesByUrlParams,
  config?: QueryObserverOptions<GetStoryPagesByUrlResponse, Error>
): UseQueryResult<GetStoryPagesByUrlResponse, Error> =>
  useQuery<GetStoryPagesByUrlResponse, Error>({
    queryKey: [Keys.STORY_PAGES_BY_URL, params],
    queryFn: () => getStoryPagesByUrl(params),
    enabled: !!params.storyUrl,
    ...config,
  })

export default useStoryPagesByUrl

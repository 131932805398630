/* eslint-disable jsx-a11y/click-events-have-key-events */
import { toast } from 'sonner'
import { Sparkles } from 'lucide-react'
import useContinueGeneratingPages from '@/api/pages/mutatations/useContinueGeneratePages'
import queryClient from '@/api'
import Keys from '@/api/keys'
import { cn } from '@/utils/shadcn/cn'
import LoadingScreen from '../LoadingScreen'

const ContinueGenerationCard = ({ storyId }: { storyId: string }) => {
  const { mutate, isPending } = useContinueGeneratingPages()

  const handleGeneration = () => {
    if (isPending) return
    mutate(storyId, {
      onError: () => {
        toast.error('Generování se nezdařilo.')
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [Keys.STORY, { storyId }] })
        queryClient.invalidateQueries({ queryKey: [Keys.STORY_PAGES, { storyId }] })
      },
    })
  }

  return (
    <div className="group relative h-[356px] w-[260px]">
      <div
        onClick={handleGeneration}
        role="button"
        tabIndex={0}
        aria-label="Continue generation"
        className={cn(
          isPending ? 'cursor-not-allowed' : 'cursor-pointer',
          'absolute left-1/2 top-1/2 z-30 flex h-[300px] w-[180px]',
          '-translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-1 rounded-[10px]',
          'border border-dashed border-slate-400 bg-white px-[10px]',
          'transition-all duration-300 group-hover:-ml-2 group-hover:scale-105 group-hover:border-solid group-hover:shadow-xl'
        )}
      >
        {!isPending ? (
          <>
            <Sparkles className="size-8 stroke-gray-700" />
            <p className="text-center text-sm font-bold text-gray-700">Vygenerovat další příběhy ze článku</p>
          </>
        ) : (
          <LoadingScreen />
        )}
      </div>
      <div className="absolute left-1/2 top-1/2 z-20 -ml-7 h-[266px] w-[160px] -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-[10px] border border-dashed border-slate-300 bg-white px-[10px] transition-all duration-300 ease-in-out group-hover:-ml-0" />
      <div className="absolute left-1/2 top-1/2 z-10 -ml-14 h-[233px] w-[140px] -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-[10px] border border-dashed border-slate-200 bg-white px-[10px] transition-all duration-300 ease-in-out group-hover:-ml-0" />
    </div>
  )
}

export default ContinueGenerationCard

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Dispatch, SetStateAction } from 'react'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../shadcn/Form'
import { Input } from '../shadcn/Input'
import { DialogFooter } from '../shadcn/Dialog'
import { Button } from '../shadcn/Button'
import { useMultiStepper } from '../Stepper/useMultiStepper'

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const StoryUrlForm = ({ setIsOpen }: Props) => {
  const { nextStep, setState } = useMultiStepper()

  const formSchema = z.object({
    storyUrl: z.string().url('Neplatná URL adresa'),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      storyUrl: '',
    },
  })

  const onSubmit = () => {
    setState({ storyUrl: form.getValues('storyUrl') })
    nextStep()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="storyUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>URL příběhu</FormLabel>
              <FormControl>
                <Input placeholder="Vložte URL existujícího příběhu" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <Button onClick={() => setIsOpen(false)} variant="ghost">
            Zrušit
          </Button>
          <Button type="submit">Vložit</Button>
        </DialogFooter>
      </form>
    </Form>
  )
}

export default StoryUrlForm

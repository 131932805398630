/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Plus } from 'lucide-react'

interface AddCardProps {
  create: () => void
}

const AddCard = ({ create }: AddCardProps) => {
  return (
    <div className="flex h-[360px] w-[260px] items-center justify-center">
      <div
        onClick={create}
        role="button"
        tabIndex={0}
        aria-label="Add new story"
        className="flex h-[300px] w-[180px] items-center justify-center rounded-xl border border-slate-400 px-11 py-12 text-slate-400 transition-transform hover:scale-105"
      >
        <Plus />
      </div>
    </div>
  )
}

export default AddCard
